<script setup lang="ts">
import { ref, onMounted } from "vue"
import CameraIcon from "./CameraIcon.vue";
import promisify from "~/utils/promisify";
import playTones from "~/utils/playTones";

const props = defineProps<{
  cameraRollReady: boolean,
}>();

const emit = defineEmits<{
  (e: 'saveImage', image: Blob): void
}>()

const viewfinder = ref<HTMLCanvasElement>();
const context = ref<CanvasRenderingContext2D>();
const allDevices = ref<MediaDeviceInfo[]>([]);
const currentDeviceIndex = ref(0);
const currentStream = ref<MediaStream>();
const selfieMode = ref(false);
const intervalId = ref();
const disabled = ref(true);
const isLeftHanded = ref(screen?.orientation?.type === "landscape-secondary");
const canvasWidth = 128;
const canvasHeight = 112;
const framerate = 1000 / 8;
const defaultColorPalette: [number, number, number][] = [
  [5, 46, 5], // darkest shade of green (in [r, g, b] format)
  [48, 98, 48],
  [139, 172, 15],
  [205, 238, 65], // lightest shade of green
];
const colorPalette = ref<[number, number, number][]>(defaultColorPalette);

const video = document.createElement('video');
const videoCanvas = document.createElement('canvas');
const videoCanvasContext = videoCanvas.getContext('2d', { willReadFrequently: true })!;
let audioContext: AudioContext;

onMounted(async () => {
  context.value = viewfinder.value!.getContext('2d')!;
  context.value.imageSmoothingEnabled = false;
  await requestUserMediaPermission();
  allDevices.value = await getAllDevices();
  screen?.orientation?.addEventListener("change", onRotate);
  document.addEventListener("visibilitychange", onVisibilityChange);
  onVisibilityChange();
});

const onRotate = (event: Event) => {
  const { type } = event.currentTarget as ScreenOrientation;
  isLeftHanded.value = (type === "landscape-secondary");

  stopCamera();
  startCamera();
};

const onVisibilityChange = () => {
  if (document.hidden) {
    stopCamera();
  } else {
    startCamera();
  }
};

const requestUserMediaPermission = async () => {
  // The user must grant us access to a camera before we can enumerate devices on Safari
  const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
  stopStream(stream);
};

const stopCamera = async () => {
  disabled.value = true;
  window.clearInterval(intervalId.value);

  currentStream.value && stopStream(currentStream.value);

  if (context.value && viewfinder.value) {
    context.value.clearRect(
      0, 0,
      viewfinder.value.width, viewfinder.value.height
    );
  }
};

const startCamera = async () => {
  const constraints: MediaStreamConstraints = {
    audio: false,
    video: {
      width: { ideal: 320 },
      height: { ideal: 240 },
      deviceId: { exact: allDevices.value[currentDeviceIndex.value].deviceId }
    },
  };
  currentStream.value = await navigator.mediaDevices.getUserMedia(constraints);

  // Necessary for mobile Safari for some reason
  // https://github.com/webrtc/samples/issues/929
  video.setAttribute('playsinline', '');

  video.srcObject = currentStream.value;
  await promisify(video, 'loadedmetadata');
  video.play();
  videoCanvas.width = canvasWidth;
  videoCanvas.height = canvasHeight;

  const canvasRatio = canvasWidth / canvasHeight;
  const videoRatio = video.videoWidth / video.videoHeight;

  let videoWidth = video.videoWidth;
  let videoHeight = video.videoHeight;
  let videoOffsetX = 0;
  let videoOffsetY = 0;

  if (canvasRatio > videoRatio) {
    videoHeight = videoWidth * (canvasHeight / canvasWidth);
    videoOffsetY = (video.videoHeight / 2) - (videoHeight / 2);
  } else {
    videoWidth = videoHeight * canvasRatio;
    videoOffsetX = (video.videoWidth / 2) - (videoWidth / 2);
  }

  const draw = () => {
    videoCanvasContext.drawImage(
      video,
      videoOffsetX,
      videoOffsetY,
      videoWidth,
      videoHeight,
      0,
      0,
      canvasWidth,
      canvasHeight,
    );

    const videoCanvasImageData = videoCanvasContext.getImageData(0, 0, videoCanvas.width, videoCanvas.height)
    filterImageData(videoCanvasImageData, colorPalette.value);
    context.value && context.value.putImageData(videoCanvasImageData, 0, 0);
  };
  selfieMode.value = isSelfieMode();
  draw();
  intervalId.value = window.setInterval(draw, framerate);
  disabled.value = false;
};

const getAllDevices = async () => {
  const deviceList = await navigator.mediaDevices.enumerateDevices();
  return deviceList.filter(device => device.kind === 'videoinput');
};

const colorPaletteForDate = async (date?: Date): Promise<[number, number, number][]> => {
  date ||= new Date();
  const seed = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  return await makeColorPalette(seed);
};

const makeColorPalette = async (seed?: string): Promise<[number, number, number][]> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(seed);
  const arrayBuffer = await crypto.subtle.digest("SHA-512", data);
  const largestUnsigned32BitInteger = 4294967295;
  const randomNumbers = [...(new Uint32Array(arrayBuffer))].map(number => number / largestUnsigned32BitInteger);

  const purpleHue = 270;
  const hues = Array(4).fill(0).map((_, index) => {
    return randomishInt(randomNumbers[index], 0, 359);
  }).sort((Ahue, Bhue) => {
    return Math.abs(Ahue - purpleHue) - Math.abs(Bhue - purpleHue)
  });

  const minSaturation = randomishInt(randomNumbers[4], 10, 25);
  const maxSaturation = randomishInt(randomNumbers[5], 20, 100);
  const saturationOffset = (maxSaturation - minSaturation) / 3

  const minLightness = randomishInt(randomNumbers[6], 0, 10);
  const maxLightness = randomishInt(randomNumbers[7], 70, 100);
  const lightnessOffset = (maxLightness - minLightness) / 3

  return [
    convertHSLtoRGB(hues[0], maxSaturation, minLightness),
    convertHSLtoRGB(hues[1], minSaturation + (saturationOffset * 2), minLightness + lightnessOffset),
    convertHSLtoRGB(hues[2], minSaturation + saturationOffset, minLightness + (lightnessOffset * 2)),
    convertHSLtoRGB(hues[3], minSaturation, maxLightness),
  ];
};

const convertHSLtoRGB = (hue: number, saturation: number, lightness: number) => {
  const div = document.createElement("div");
  div.style.display = "none";
  div.style.color = `hsl(${hue} ${saturation}% ${lightness}%)`;
  document.body.append(div);
  const colorString = window.getComputedStyle(div).color;
  div.remove();

  const stringArray = /rgb\((\d+), (\d+), (\d+)\)/.exec(colorString)?.slice(1);
  return stringArray?.map(n => Number.parseInt(n)) as [number, number, number];
};

const randomishInt = (input: number, minimum: number, maximum: number) => {
  minimum = Math.ceil(minimum);
  maximum = Math.floor(maximum);
  return Math.floor(input * (maximum - minimum + 1)) + minimum;
};

const filterImageData = (imageData: ImageData, palette: [number, number, number][]) => {
  const threshold = 129;
  const bayerThresholdMap = [
    [15, 135, 45, 165],
    [195, 75, 225, 105],
    [60, 180, 30, 150],
    [240, 120, 210, 90],
  ];

  let data = imageData.data;
  let width = imageData.width;
  let colorIndex = 0;

  for (let i = 0; i < data.length; i += 4) {
    let r = data[i];
    let g = data[i + 1];
    let b = data[i + 2];

    // Turn this pixel greyscale
    let brightness = ((r * 5) + (g * 6) + (b * 1)) >>> 3;

    if (brightness <= 63) {
      // Darkest shade
      colorIndex = 0;
    } else if (brightness >= 64 && brightness <= 127) {
      colorIndex = 1;
    } else if (brightness >= 128 && brightness <= 191) {
      colorIndex = 2;
    } else if (brightness >= 192) {
      // Brightest shade
      colorIndex = 3;
    }

    // 4x4 Bayer ordered dithering algorithm
    // Inspired by forresto on Stack Overflow
    // https://stackoverflow.com/q/12422407
    var x = i / 4 % width;
    var y = Math.floor(i / 4 / width);
    var map = Math.floor((brightness + bayerThresholdMap[x % 4][y % 4]) / 2);

    // A monochrome ordered dithering algorithm usually uses this value to set the pixel as black or white
    // But here we use it to shift the color along the palette either darker or lighter
    colorIndex += (map < threshold) ? -1 : 1;
    colorIndex = Math.max(0, Math.min(palette.length - 1, colorIndex));

    data[i] = palette[colorIndex][0];
    data[i + 1] = palette[colorIndex][1];
    data[i + 2] = palette[colorIndex][2];
  }
};

const stopStream = (stream: MediaStream) => {
  stream.getTracks().forEach(track => {
    track.stop();
    stream.removeTrack(track);
  });
}

const switchCamera = () => {
  stopCamera();

  if (currentDeviceIndex.value < allDevices.value.length - 1) {
    currentDeviceIndex.value++;
  } else {
    currentDeviceIndex.value = 0;
  }
  startCamera();
};

const isSelfieMode = () => {
  const currentDevice = allDevices.value[currentDeviceIndex.value];
  return (
    currentStream.value?.getVideoTracks()[0].getSettings().facingMode === 'user' ||
    currentDevice?.label.toLowerCase().includes('facetime')
  );
};

const getAudioContext = () => {
  return audioContext ||= new AudioContext();
};

const playShutterSound = async () => {
  // Type can be one of:
  // "sawtooth" | "sine" | "square" | "triangle"
  await playTones(getAudioContext(), [
    { type: 'sine', frequency: 900, duration: 0.01 },
    { duration: 0.1 },
    { type: 'sine', frequency: 1000, duration: 0.01 },
    { duration: 0.1 },
    { type: 'triangle', frequency: 1200, duration: 0.03 },
  ]);
};

const saveImage = async () => {
  if (!viewfinder.value) {
    return false;
  }

  playShutterSound();
  disabled.value = true;

  viewfinder.value.toBlob((blob) => {
    if (blob) {
      emit('saveImage', blob);
      disabled.value = false;
    }
  })
};
</script>

<template>
  <div :class="['wrapper', { 'orientation-left-handed': isLeftHanded }]">
    <canvas :width="canvasWidth" :height="canvasHeight" :class="['viewfinder', { selfieMode }]" ref="viewfinder"
      @dblclick.prevent="switchCamera"></canvas>
    <div className="controls">
      <button :disabled="disabled || !cameraRollReady" title="Take Photo" @click="saveImage">
        <span role="img" aria-label="Camera icon">
          <CameraIcon class="camera-icon" width="100%" height="100%" />
        </span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.orientation-left-handed.wrapper {
  flex-direction: row-reverse;
}

@media (orientation: landscape) {
  .wrapper {
    flex-direction: row;
  }
}

canvas,
button {
  background: var(--darkest-green);
  border: 1px solid var(--lightest-green);
}

canvas {
  display: block;
  width: 100%;
  margin: auto;
  image-rendering: pixelated;
}

.selfieMode {
  transform: scale(-1, 1);
}

button {
  appearance: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  display: block;
  width: 50px;
  padding: 0.8em;
}

button:enabled:focus,
button:enabled:hover,
button:enabled:active {
  background: var(--darkest-green);
  border: 1px solid var(--lightest-green);
  filter: unset;
}

button:enabled:active {
  background: rgb(var(--darkest-green-values), 0.5);
}

button:enabled:active svg {
  opacity: 0.5;
}

button:disabled {
  cursor: pointer;
  opacity: 1;
}

.camera-icon {
  fill: var(--lightest-green);
}
</style>
