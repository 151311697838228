<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3'
import SlugInput from "~/components/SlugInput.vue"

const props = defineProps<{
  slug: string,
}>();

const form = useForm({
  slug: props.slug,
})

const onSubmit = () => {
  form.put(`/printers/${props.slug}`);
}

const onSlugError = (error: string) => {
  form.setError('slug', error);
}
</script>

<template>
  <form class="slug-form" @submit.prevent="onSubmit">
    <div class="input-wrapper">
      <SlugInput :minlength="3" :maxLength="32" fieldName="Printer name" placeholder="some_cool_printer_name"
        :autofocus="true" v-model="form.slug" @error="onSlugError" @clearError="form.clearErrors('slug')"
        :disabled="form.processing" />
      <button class="save-button" type="submit" :disabled="form.processing">save</button>
    </div>
    <div v-if="form.hasErrors" class="error">
      <p v-for="message in form.errors">⚠️ {{ message }}</p>
      <button @click="form.reset(); form.clearErrors()" type="button">oh, sorry</button>
    </div>
  </form>
</template>

<style scoped>
.text-input {
  width: 100%;
}

.input-wrapper {
  display: flex;
}

.input-wrapper input {
  flex-grow: 1;
}

.slug-form .save-button {
  padding: 0.75em;
  font-size: inherit;
}

.error {
  color: var(--red);
}
</style>
