import promisify from "./promisify";

export default async (audioContext: AudioContext, sounds: { type?: OscillatorType, frequency?: number, duration: number }[]) => {
  let delay = 0;
  const promises: Promise<Event>[] = [];
  for (const { type, frequency, duration } of sounds) {
    const promise = playSingle(audioContext, type, frequency, duration, delay);
    promises.push(promise);
    delay += duration;
  }
  return await Promise.all(promises);
}

async function playSingle(audioContext: AudioContext, type?: OscillatorType, frequency = 500, duration = 0.1, delay = 0) {
  const oscillator = audioContext.createOscillator();

  if (type) {
    oscillator.type = type;
    oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);
    oscillator.connect(audioContext.destination);
  }

  oscillator.start(audioContext.currentTime + delay);
  oscillator.stop(audioContext.currentTime + delay + duration);
  return promisify(oscillator, 'ended');
};
