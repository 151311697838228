<script lang="ts" setup>
import { ref } from "vue"
import slugify from "~/utils/slugify"
import isValidSlug from "~/utils/isValidSlug"

const props = defineProps<{
  modelValue?: string,
  fieldName?: string,
  minlength?: number,
  maxLength?: number,
}>();

const fieldName = ref(props.fieldName || "Your handle");
const minlength = ref(props.minlength || 3);
const maxLength = ref(props.maxLength || 32);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'error', value: string): void
  (e: 'clearError'): void
}>()

const onInput = (event: Event) => {
  const input = event.target as HTMLInputElement;
  const value = slugify(input.value);

  emit('clearError');
  if (!isValidSlug(value)) {
    emit('error', `${fieldName.value} can only contain letters, numbers, and underscores. (Also it can't contain more than ${maxLength.value} of those)`);
  }

  emit('update:modelValue', value);
};
</script>

<template>
  <input class="text-input" @input="onInput" :value="modelValue" :required="true" :minlength="minlength"
    :maxlength="maxLength" spellcheck="false" />
</template>

<style scoped>
input {
  width: 100%;
}
</style>