<script lang="ts" setup>
import { ref } from "vue"
import { Link } from '@inertiajs/vue3'

const props = defineProps<{
  printerSlug: string,
  enabled: boolean,
}>();
</script>

<template>
  <Link method="patch" :href="`/printers/${props.printerSlug}`" :data="{ enabled: !enabled }" as="button"
    :class="enabled ? 'success' : 'danger'">
  {{ enabled ? "enabled" : "disabled" }}
  </Link>
</template>

<style scoped>
input {
  cursor: pointer;
}
</style>
