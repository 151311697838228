<script setup lang="ts">
import { ref, onMounted } from "vue"
import type { IDBPDatabase } from "idb";
import type { DithercamDB } from "~/db/idb";

const emit = defineEmits<{
  (e: "ready"): void
  (e: "selectImage", image: { id: number, blob: Blob, blobURL: string, createdAt: Date }): void
}>()

const db = ref<IDBPDatabase<DithercamDB>>();
const images = ref<{
  id: number,
  blob: Blob;
  createdAt: Date;
  blobURL: string;
}[]>([]);

const reloadImages = async () => {
  images.value = (await db.value!.getAllFromIndex("images", "createdAt")).map((image) => {
    const blob = new Blob([image.arrayBuffer]);
    return { id: image.id!, blob, blobURL: URL.createObjectURL(blob), ...image };
  }).reverse();
};

onMounted(async () => {
  const dbPromise = (await import("~/db/idb")).default;
  db.value = await dbPromise;
  emit("ready");
  reloadImages();
});

const saveImage = async (blob: Blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  await db.value!.add("images", { arrayBuffer, createdAt: new Date() });
  reloadImages();
};

const deleteImage = async (id: number) => {
  await db.value!.delete("images", id);
  reloadImages();
};
defineExpose({ saveImage, deleteImage });
</script>

<template>
  <div class="camera-roll">
    <button v-for="image in images" @click="emit('selectImage', image)">
      <img :src="image.blobURL" />
    </button>
  </div>
</template>

<style scoped>
.camera-roll {
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.camera-roll button {
  border: none;
  padding: 0;
}
</style>
