<script lang="ts" setup>
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import FlashMessage from "~/components/FlashMessage.vue"
import GalleryLink from "~/components/GalleryLink.vue"
import RoleIcon from "~/components/RoleIcon.vue"
import ToggleEnabled from './components/ToggleEnabled.vue';
import PrinterSlugForm from './components/PrinterSlugForm.vue';
import ResetApiKeyButton from './components/ResetApiKeyButton.vue';

const props = defineProps<{
  printer: {
    slug: string,
    url: string,
    enabled: boolean,
    hasApiKey: boolean,
    members: { handle: string, role: 'manager' | 'admin' }[],
    invites?: { code: string, url: string }[],
    canUpdate: boolean,
    canInvite: boolean,
    canResetApiKey: boolean,
  },
  createInviteUrl: string,
}>();

const flashMessage = ref();
const showPrinterSlugModal = ref(false);

const copyToClipboard = async (url: string) => {
  navigator.clipboard.writeText(url).then(() => {
    flashMessage.value = "Invite link copied to clipboard";
  }).catch(() => {
    prompt("Copy this URL and send it to someone to invite them", url);
  });
};

</script>

<template>
  <template v-if="printer.hasApiKey">
    <Link :href="`/printers/${printer.slug}`" class="print-queue-link dashed-underline">&lt; back to print queue</Link>

    <p>
      Printer status:
      <ToggleEnabled :printerSlug="printer.slug" :enabled="printer.enabled" />
    </p>

    <p>Public URL:
      <Link :href="printer.url" class="dashed-underline">{{ printer.url }}</Link>
    </p>
    <template v-if="printer.canUpdate">
      <p>Change the last part of that URL:</p>
      <PrinterSlugForm :slug="printer.slug" @close="showPrinterSlugModal = false" />
    </template>
  </template>
  <template v-else>
    <h1>One more thing!</h1>
    <p>To start printing, you'll need to paste this printer's API key into your Ditherprint app.</p>
    <p>Once you've done that, Tell your friends to visit
      <Link :href="printer.url" class="dashed-underline">{{ printer.url }}</Link> so they can print to your
      printer!
    </p>
  </template>
  <div class="reset-api-key-button">
    <ResetApiKeyButton v-if="printer.canResetApiKey" :hasApiKey="printer.hasApiKey" :printerSlug="printer.slug" />
  </div>

  <h1>Members</h1>
  <ul class="members">
    <li v-for="{ handle, role } in printer.members" :key="handle">
      <RoleIcon :role="role" class="role-icon" />
      <GalleryLink v-if="handle" class="dashed-underline" :slug="handle" />
      <template v-else>unnamed user</template>
    </li>
  </ul>

  <section v-if="printer.canInvite">
    <h1>Pending Members</h1>
    <ul class="invites">
      <li v-for="{ code, url } in printer.invites" :key="code" class="invite">
        <a :href="url" @click.prevent="copyToClipboard(url)" class="dashed-underline">
          {{ code }}
        </a>
      </li>
    </ul>

    <Link class="create-invite" :href="createInviteUrl" method="post" :data="{ printer_slug: printer.slug }" as="button"
      type="button" :preserveScroll="true">Create Invite</Link>
  </section>

  <FlashMessage v-if="flashMessage" @timeout="flashMessage = undefined">
    {{ flashMessage }}
  </FlashMessage>
</template>

<style scoped>
.print-queue-link {
  display: inline-block;
  margin-bottom: 1em;
}

.reset-api-key-button {
  margin-top: 1em;
}

.members {
  list-style: none;
  padding: 0;
}

.members li {
  margin-bottom: 1em;
}

.members .role-icon {
  margin-right: 0.5em;
}

.invites li {
  margin-bottom: 1em;
  list-style-position: inside;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 0.25em;
}
</style>
