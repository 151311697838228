<script lang="ts" setup>
import { ref, computed } from "vue"
import { useForm } from '@inertiajs/vue3'
import Dialog from "~/components/Dialog.vue";
import GalleryVisibilityIcon from "./GalleryVisibilityIcon.vue"
import SlugInput from "~/components/SlugInput.vue"

const showForm = ref(false)

const form = useForm({
  slug: undefined,
  visibility: 'public',
})

const hideForm = () => {
  form.reset();
  form.clearErrors();
  showForm.value = false;
}

const onSubmit = () => {
  form.post('/galleries', {
    onSuccess: () => {
      hideForm();
    },
  })
}
const onSlugError = (error: string) => {
  form.setError('slug', error);
}

const disabled = computed(() => form.processing || form.hasErrors)
</script>

<template>
  <button @click="showForm = true">Create a new Gallery</button>
  <Dialog v-if="showForm" class="dialog" @close="hideForm">
    <div class="header">
      New Gallery
      <button class="close danger" @click="hideForm">cancel</button>
    </div>
    <form class="username-form" @submit.prevent="onSubmit">
      <SlugInput placeholder="some_cool_gallery_name" :autofocus="true" v-model="form.slug" @error="onSlugError"
        @clearError="form.clearErrors('slug')" :disabled="form.processing" />

      <p>Unless otherwise specified, who can see the pictures in this gallery?</p>
      <select v-model="form.visibility">
        <option value="public">
          <GalleryVisibilityIcon visibility="public" /> The whole world wide web
        </option>
        <option value="logged_in">
          <GalleryVisibilityIcon visibility="logged_in" /> All logged-in Dithercam users
        </option>
        <option value="friends">
          <GalleryVisibilityIcon visibility="friends" /> Only people you follow
        </option>
        <option value="members">
          <GalleryVisibilityIcon visibility="members" /> Only members of this gallery (invite-only)
        </option>
      </select>
      <small>(You can always restrict visibility further on a picture-to-picture basis)</small>

      <div v-if="form.hasErrors" class="error">
        <p v-for="message in form.errors">⚠️ {{ message }}</p>
        <p>
          <button @click="form.reset(); form.clearErrors()" type="button">oh, sorry</button>
        </p>
      </div>
      <menu>
        <button class="save-button" type="submit" :disabled="disabled">Save</button>
      </menu>
    </form>
  </Dialog>
</template>

<style scoped>
.dialog {
  width: 100%;
  max-width: 450px;
}

.dialog .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

select {
  font-size: 1em;
  padding: 0.25em;
  cursor: pointer;
  background: #000;
  color: var(--off-green);
}

small {
  display: block;
  margin: 1em 0;
  opacity: 0.75;
}

menu {
  display: flex;
  padding: 0;
  justify-content: space-evenly;
  margin-bottom: 0;
}

.error {
  color: var(--red);
}
</style>