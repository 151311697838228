<script setup lang="ts">
import { ref, onMounted } from "vue"

const emit = defineEmits<{
  (e: "close"): void
}>()

const dialog = ref<HTMLDialogElement>();

onMounted(() => {
  dialog?.value?.showModal();
});

const onDialogClose = () => {
  emit("close");
};
</script>

<template>
  <dialog @close="onDialogClose" ref="dialog">
    <slot></slot>
  </dialog>
</template>

<style scoped>
dialog {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  border: 1px solid var(--orange);
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}
</style>
