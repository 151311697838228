<script lang="ts" setup>
import { ref, onMounted } from 'vue'

const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
})
</script>

<template>
  <slot v-if="mounted"></slot>
</template>

<style scoped></style>
