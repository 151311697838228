<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue'
import { Link, Head } from '@inertiajs/vue3'
import defaultAltText from "~/utils/defaultAltText"
import Image from "~/components/Image.vue"

const props = defineProps<{
  currentUser?: {
    primaryGallerySlug: string,
  },
  slug: string,
  canPost: boolean
  images: {
    dataUri: any,
    altText: string,
    permalink: string,
  }[],
}>();

onMounted(() => {
  document.documentElement.classList.add('gallery')
})

onUnmounted(() => {
  document.documentElement.classList.remove('gallery')
})
</script>

<template>

  <Head :title="slug"></Head>
  <div v-if="canPost && images.length == 0" class="no-images-yet">
    You haven't uploaded any pictures from
    <Link class="dashed-underline" href="/">the Dithercam</Link> yet!
  </div>
  <div class="images">
    <Link v-for="{ dataUri, altText, permalink } in images" :href="permalink">
    <Image :src="dataUri" :alt="altText || defaultAltText" />
    </Link>
  </div>

  <footer class="footer">Powered by
    <Link class="dashed-underline" href="/">Dither.cam</Link>
  </footer>
</template>

<style>
html.gallery,
.gallery body,
.gallery #app {
  height: 100%;
}

.gallery .page {
  max-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

<style scoped>
.images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.images img {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
}

.no-images-yet {
  margin: auto;
  max-width: 330px;
  text-align: center;
}

.footer {
  margin-top: 1em;
  text-align: center;
}
</style>
