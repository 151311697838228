<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { Link, router, Head } from '@inertiajs/vue3'
import betterFetch from "~/utils/betterFetch";
import buildImagePermalink from "~/utils/buildImagePermalink"
import makePossessive from "~/utils/makePossessive"
import buildImagePngUrl from "~/utils/buildImagePngUrl"
import defaultAltText from "~/utils/defaultAltText"
import formateDate from "~/utils/formateDate"
import Image from "~/components/Image.vue"
import ImageEdit from "~/components/ImageEdit.vue"
import GalleryLink from "~/components/GalleryLink.vue"

const props = defineProps<{
  currentUser?: {
    primaryGallerySlug: string,
  },
  gallerySlug: string,
  image: {
    id: string,
    dataUri: any,
    altText: string,
    caption: string,
    createdAt: string,
    slug: string,
    permalink: string,
    neighbors: {
      dataUri: string
      permalink: string
    }[],
    isEditable: boolean,
  },
}>();


// TODO: Consider if we wanna port this over somehow
// definePageMeta({
//   validate: async (route) => {
//     return isValidSlug(route.params.imgSlug as string)
//   }
// });

const editing = ref(false);
const imageEdit = ref<typeof ImageEdit>();
const saveDisabled = ref(false);

const image = props.image;
const caption = ref(image.caption);
const slug = ref(image.slug);
const altText = ref(image.altText);

const onDoneEditing = () => {
  editing.value = false;
};

const showDeleteDialog = async () => {
  if (!confirm("Are you sure you want to make this picture go away forever? There's no undo!")) {
    return;
  }

  let success;
  try {
    success = await betterFetch(`/galleries/${props.gallerySlug}/images/${image.id}`, "delete");
    if (success) {
      router.get(`/~${props.gallerySlug}`);
    }
  } catch (error) {
    console.log(error)
    alert("That didn't work. Maybe try again?");
  }
};

const onUpdateSlug = (slug: string) => {
  const newPermalink = buildImagePermalink(props.gallerySlug, slug);
  if (newPermalink !== image.permalink) {
    router.get(newPermalink);
  }
};

const onChangeSaveDisabled = (value: boolean) => {
  saveDisabled.value = value;
};

const [previous, next] = props.image.neighbors;

onMounted(() => {
  document.documentElement.classList.add('permalink')
})

onUnmounted(() => {
  document.documentElement.classList.remove('permalink')
})
</script>

<template>

  <Head :title="`~${gallerySlug}/${image.slug}.bmp`">
    <meta head-key="description" name="description"
      :content="image.caption || `${makePossessive(gallerySlug)} image`" />
    <meta name="og:image" :content="buildImagePngUrl(gallerySlug, image.slug, 10)">
    <meta name="og:image:type" content="image/png">
    <meta name="og:image:width" content="1280">
    <meta name="og:image:height" content="1120">
    <meta name="og:image:alt" :content="altText || defaultAltText">
  </Head>
  <div class="top">
    <menu>
      <li>
        <button v-if="editing" @click="showDeleteDialog" class="danger">delete</button>
        <GalleryLink v-else class="dashed-underline" :slug="gallerySlug" />
      </li>
      <li v-if="image.isEditable">
        <button v-if="!editing" @click="editing = true">edit</button>
        <button v-if="editing" @click="imageEdit?.save()" :disabled="saveDisabled">save</button>
      </li>
    </menu>

    <Image class="image" :src="image.dataUri" :alt="altText || defaultAltText" />
    <div class="date-uploaded">Uploaded {{ formateDate(image.createdAt) }}</div>

    <div class="caption" v-if="caption && !editing">{{ caption }}</div>
    <ImageEdit :gallerySlug="gallerySlug" ref="imageEdit" :id="image.id" @done="onDoneEditing" v-if="editing"
      v-model:caption="caption" v-model:altText="altText" :slug="slug" @update:slug="onUpdateSlug"
      @changeSaveDisabled="onChangeSaveDisabled" />
  </div>
  <nav class="neighbors" v-if="!editing && (previous || next)">
    <Link aria-label="Previous image" v-if="previous" class="neighbor previous" :href="previous.permalink">
    <img width="30" :src="previous.dataUri" alt="" />
    </Link>
    <div v-else class="neighbor previous"></div>
    <Link aria-label="Next image" v-if="next" class="neighbor next" :href="next.permalink">
    <img width="30" :src="next.dataUri" alt="" />
    </Link>
  </nav>
</template>

<style>
html.permalink,
.permalink body,
.permalink #app {
  height: 100%;
}

.permalink .page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

<style scoped>
menu {
  display: flex;
  margin: 0;
  padding: 0;
  margin-bottom: 1em;
  justify-content: space-between;
  align-items: center;
}

menu li {
  list-style: none;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
}

.date-uploaded {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.8em;
  color: rgb(var(--off-green-values), 0.6);
}

.caption {
  white-space: pre-wrap;
}

.neighbors {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.5em;
}

.neighbor {
  display: flex;
}

.neighbor img {
  height: 1em;
}

.neighbor.previous img {
  clip-path: polygon(80% 0%, 80% 100%, 20% 50%);

}

.neighbor.next img {
  clip-path: polygon(20% 0%, 20% 100%, 80% 50%);
}
</style>
