<script lang="ts" setup>
import { Link, router } from "@inertiajs/vue3"
import betterFetch from "~/utils/betterFetch";
import Image from "~/components/Image.vue"

const props = defineProps<{
  printerSlug: string,
  jobs: {
    id: string,
    dataUri: string,
    createdAtRelative?: string,
    printedAtRelative?: string,
  }[]
}>();



const deleteImage = async (id: string) => {
  if (
    !confirm("Are you sure you want to make this print job go away forever? There's no undo!")
  ) {
    return;
  }

  await betterFetch(`/printers/${props.printerSlug}/jobs/${id}`, "delete");
  router.reload();
}
</script>

<template>
  <ul class="jobs">
    <li v-for="{ id, dataUri, createdAtRelative, printedAtRelative } in jobs" :key="id">
      <Image :src="dataUri" alt="An image someone printed" :width="64" :height="56" />
      <p>{{ printedAtRelative || createdAtRelative }}</p>
      <Link :href="`/printers/${props.printerSlug}/jobs/${id}/reprint`" method="post" as="button" type="button"
        :preserveScroll="true">print another</Link>

      <button v-if="!printedAtRelative" @click="deleteImage(id)" class="danger" type="button">delete</button>
    </li>
  </ul>
</template>

<style scoped>
.jobs {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.jobs li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}
</style>
