import { createSSRApp, h } from 'vue'
import type { DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3'
import title from "~/utils/title"
import Layout from "~/layouts/Default.vue"

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob<DefineComponent>('../Pages/**/*.vue', { eager: true })
    const page = pages[`../Pages/${name}.vue`]
    page.default.layout = page.default.layout || Layout
    return page
  },
  setup({ el, App, props, plugin }) {
    createSSRApp({ render: () => h(App, props) })
      .use(plugin)
      .mount(el)
  },
  title,
})
