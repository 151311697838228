<script lang="ts" setup>
import { ref, computed } from "vue"
import { useForm } from '@inertiajs/vue3'
import Dialog from "~/components/Dialog.vue";
import SlugInput from "~/components/SlugInput.vue"

const showForm = ref(false)

const form = useForm({
  slug: undefined,
})

const hideForm = () => {
  form.reset();
  form.clearErrors();
  showForm.value = false;
}

const onSubmit = () => {
  form.post('/printers', {
    onSuccess: () => {
      hideForm();
    },
  })
}
const onSlugError = (error: string) => {
  form.setError('slug', error);
}

const disabled = computed(() => form.processing || form.hasErrors)
</script>

<template>
  <button @click="showForm = true">Create a new Printer</button>
  <Dialog v-if="showForm" class="dialog" @close="hideForm">
    <div class="header">
      New Printer
      <button class="close danger" @click="hideForm">cancel</button>
    </div>
    <form class="username-form" @submit.prevent="onSubmit">
      <SlugInput :minlength="3" :maxLength="32" fieldName="Printer name" placeholder="some_cool_printer_name"
        :autofocus="true" v-model="form.slug" @error="onSlugError" @clearError="form.clearErrors('slug')"
        :disabled="form.processing" />

      <div v-if="form.hasErrors" class="error">
        <p v-for="message in form.errors">⚠️ {{ message }}</p>
        <p>
          <button @click="form.reset(); form.clearErrors()" type="button">oh, sorry</button>
        </p>
      </div>
      <menu>
        <button class="save-button" type="submit" :disabled="disabled">save</button>
      </menu>
    </form>
  </Dialog>
</template>

<style scoped>
.dialog {
  width: 100%;
  max-width: 450px;
}

.dialog .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

menu {
  display: flex;
  padding: 0;
  justify-content: space-evenly;
  margin-bottom: 0;
}

.error {
  color: var(--red);
}
</style>