<script lang="ts" setup>
const props = defineProps<{
  visibility: 'public' | 'logged_in' | 'friends' | 'members'
}>();

const [emoji, title] = {
  public: ['🌐', 'The whole world wide web'],
  logged_in: ['👤', 'All logged-in Dithercam users'],
  friends: ['♥️', 'Only people you follow'],
  members: ['🔒', 'Only members of this gallery (invite-only)'],
}[props.visibility]
</script>

<template>
  <span :title="title">
    {{ emoji }}
  </span>
</template>

<style scoped>
span {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
  font-family: initial;
  cursor: help;
}
</style>
