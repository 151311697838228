<script setup lang="ts">
import { ref } from "vue"

const props = defineProps<{
  modelValue?: string,
  placeholder: string,
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const value = ref(props.modelValue);

const onInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  value.value = target.value;
  emit('update:modelValue', target.value);
};
</script>

<template>
  <div :data-value="value || placeholder" class="wrapper">
    <textarea class="text-input" :placeholder="placeholder" :value="modelValue" @input="onInput" rows="1"></textarea>
  </div>
</template>

<style scoped>
/* Inspired by: */
/* https://css-tricks.com/auto-growing-inputs-textareas/#aa-other-ideas */
.wrapper {
  display: grid;
  overflow: hidden;
  width: 100%;
  vertical-align: top;
  align-items: center;
  position: relative;
  align-items: stretch;
}

.wrapper::after,
.wrapper textarea {
  width: 100%;
  min-height: 105px;
  grid-area: 2 / 1;
  font: inherit;
  margin: 0;
  resize: none;
  appearance: none;
}

.wrapper::after {
  content: attr(data-value) " ";
  visibility: hidden;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  padding: 0.75em;
  border: 1px solid transparent;
}
</style>
