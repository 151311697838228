<script lang="ts" setup>
const props = defineProps<{
  role: 'manager' | 'admin'
}>();

const [emoji, title] = {
  manager: ['👤', 'manager'],
  admin: ['🌟', 'admin'],
}[props.role]
</script>

<template>
  <span :title="title">
    {{ emoji }}
  </span>
</template>

<style scoped>
span {
  display: inline-block;
  width: 1em;
  height: 1em;
  text-align: center;
  font-family: initial;
  cursor: help;
}
</style>
