<script lang="ts" setup>
import { computed } from 'vue'
import { useForm } from '@inertiajs/vue3'
import SlugInput from "~/components/SlugInput.vue"

const props = defineProps<{
  slug?: string,
}>();

const form = useForm({
  slug: props.slug,
})

const onSlugError = (error: string) => {
  form.setError('slug', error);
}

const disabled = computed(() => form.processing || form.hasErrors)
</script>

<template>
  <form class="username-form" @submit.prevent="form.patch('/galleries/primary')">
    <SlugInput placeholder="some_cool_user_handle" v-model="form.slug" @error="onSlugError"
      @clearError="form.clearErrors('slug')" :disabled="form.processing" />
    <button class="save-button" type="submit" :disabled="disabled">Save</button>
  </form>

  <div v-if="form.hasErrors" class="error">
    <p v-for="message in form.errors">⚠️ {{ message }}</p>
    <p>
      <button @click="form.reset(); form.clearErrors()" type="button">oh, sorry</button>
    </p>
  </div>
</template>

<style scoped>
.username-form {
  display: flex;
}

.username-form input {
  flex-grow: 1;
}

.username-form .save-button {
  padding: 0.75em;
  font-size: inherit;
}

.error {
  color: var(--red);
}
</style>
