export default async (blob: Blob, multiplier: number): Promise<Blob> => {
  const image = await createImageBitmap(blob);
  const newWidth = image.width * multiplier;
  const newHeight = image.height * multiplier;

  const canvas = document.createElement("canvas");
  canvas.width = newWidth;
  canvas.height = newHeight;

  const context = canvas.getContext("2d")!;
  context.imageSmoothingEnabled = false;

  context.drawImage(image, 0, 0, newWidth, newHeight);

  return await new Promise(function (resolve) {
    canvas.toBlob(function (blob) {
      resolve(blob!);
    })
  });
}
