<script lang="ts" setup>
import { ref } from "vue"
import { Link } from '@inertiajs/vue3'
import betterFetch from "~/utils/betterFetch";
import Dialog from "~/components/Dialog.vue";
import FlashMessage from "~/components/FlashMessage.vue"

const props = defineProps<{
  hasApiKey: boolean,
  printerSlug: string,
}>();

const showModal = ref(false);
const apiKey = ref();
const flashMessage = ref();

const onClick = async () => {
  if (props.hasApiKey) {
    showModal.value = true;
  } else {
    resetKey();
  }
}

const resetKey = async () => {
  showModal.value = false

  const url = `/printers/${props.printerSlug}/api_key`;
  apiKey.value = (await betterFetch(url, "put")).apiKey;
}

const copyToClipboard = async (key: string) => {
  navigator.clipboard.writeText(key).then(() => {
    flashMessage.value = "API key copied to clipboard";
  }).catch(() => {
    prompt("Copy this and paste it into your Ditherprint app", key);
  });
};
</script>

<template>
  <button v-if="!apiKey" @click="onClick" type="button">{{ hasApiKey ? "reset" : "generate" }} api key</button>
  <Dialog v-if="showModal" @close="showModal = false" class="dialog">
    <h1>Reset Printer API Key?</h1>

    <p>Resetting your API key can have <em>dire consequences</em>.</p>

    <p>Namely, your printer will stop working until you
      paste the new API key into your Ditherprint app.</p>

    <menu>
      <li>
        <button class="danger" @click="showModal = false">don't do that</button>
      </li>
      <li>
        <button class="success" @click="resetKey">okay, good</button>
      </li>
    </menu>
  </Dialog>

  <template v-if="apiKey">
    <p>Your new API key is:</p>
    <code @click="copyToClipboard(apiKey)">{{ apiKey }}</code>
    <p>For your security and ours, we won't be showing you this key ever again, so you should put it in your Ditherprint
      app right now.</p>

    <template v-if="!hasApiKey">
      <Link :href="`/printers/${printerSlug}`" as="button">view print queue</Link>
    </template>
  </template>

  <FlashMessage v-if="flashMessage" @timeout="flashMessage = undefined">
    {{ flashMessage }}
  </FlashMessage>
</template>

<style scoped>
.dialog {
  width: 100%;
  max-width: 400px;
}

.dialog h1 {
  margin: 0;
  font-size: 1.25em;
}

menu {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

menu li {
  list-style: none;
}

code {
  display: block;
  color: #00f;
  padding: 0.25em;
  background: var(--grey);
  word-wrap: break-word;
}
</style>
