<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'

const props = defineProps<{
  sessionId: string,
}>();

const form = useForm({
  passwordless: { token: null },
})

const disabled = form.processing
</script>

<template>
  <form class="form" @submit.prevent="form.patch(`/users/sign_in/${sessionId}`)">
    <input class="text-input" name="token" type="token" v-model="form.passwordless.token" :required="true"
      :autofocus="true" autocomplete="off" :disabled="disabled" />
    <button class="submit-button" type="submit" :disabled="disabled">Confirm</button>
  </form>
</template>

<style scoped>
.form {
  display: flex;
}

.form input {
  flex-grow: 1;
}

.form .submit-button {
  padding: 0.75em;
  font-size: inherit;
}
</style>
