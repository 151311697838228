<script setup lang="ts">
import { ref } from "vue"
import { Link } from '@inertiajs/vue3'
import resizeImage from "~/utils/resizeImage";
import betterFetch from "~/utils/betterFetch";
import formateDateForFilename from "~/utils/formateDateForFilename";
import ClientOnly from "~/components/ClientOnly.vue";
import FlashMessage from "~/components/FlashMessage.vue";
import ImageEdit from "~/components/ImageEdit.vue";
import Dialog from "~/components/Dialog.vue";
import CameraRoll from "./components/CameraRoll.vue";
import DitherCamera from "./components/DitherCamera.vue";

const props = defineProps<{
  isLoggedIn: boolean,
  galleries: { id: string, slug: string }[],
  printerSlug?: string,
}>();

const selectedImage = ref<{ id: number, blobURL: string, filename: string, blob: Blob }>();
const dialog = ref<HTMLDialogElement>();
const upscale = ref<boolean>(true);
const flashMessage = ref<{ url: string, filename: string }>();
const printedFlashMessage = ref<boolean>();
const printErrorFlashMessage = ref<boolean>();
const upscaleAmount = 10;
const cameraRoll = ref<typeof CameraRoll>();
const cameraRollReady = ref(false);

const saveImage = (blob: Blob) => {
  if (!cameraRoll.value) {
    return;
  }

  cameraRoll.value.saveImage(blob);
}

const deleteImage = () => {
  if (
    !cameraRoll.value ||
    !selectedImage.value ||
    !confirm("Are you sure you want to make this picture go away forever? There's no undo!")
  ) {
    return;
  }

  cameraRoll.value.deleteImage(selectedImage.value.id);
  selectedImage.value = undefined;
}

const selectImage = (image: { id: number, blob: Blob, blobURL: string, createdAt: Date }) => {
  const filename = buildFilename(image.createdAt);
  selectedImage.value = { ...image, filename };
};

const downloadImage = async () => {
  if (!selectedImage.value) {
    return;
  }

  const { blobURL, blob, filename } = selectedImage.value;
  let href = blobURL;

  if (upscale.value) {
    const upscaledBlob = await resizeImage(blob, upscaleAmount);
    href = URL.createObjectURL(upscaledBlob);
  }

  const link = document.createElement("a");
  link.download = filename;
  link.href = href;
  link.click();
};

const shareImage = async () => {
  if (!selectedImage.value) {
    return;
  }

  const { blobURL, blob, filename } = selectedImage.value;
  let blobToShare = blob;
  if (upscale.value) {
    blobToShare = await resizeImage(blob, upscaleAmount);
  }

  const file = new File([blobToShare], filename, { type: "image/png" });
  const toShare = { files: [file] };
  if (!navigator.canShare(toShare)) {
    return alert("Sorry, your browser doesn't support this feature :(");
  }

  navigator.share(toShare);
};

const onImageUpload = (permalink?: string, filename?: string) => {
  selectedImage.value = undefined;
  if (permalink && filename) {
    flashMessage.value = { url: permalink, filename };
  }
};

const printImage = async () => {
  if (!selectedImage.value) {
    return;
  }

  let url = `/printers/${props.printerSlug}/jobs`;

  const { success } = await betterFetch(url, "post", {
    image_data: selectedImage.value.blob,
  });

  if (success) {
    printedFlashMessage.value = true;
    selectedImage.value = undefined;
  } else {
    printErrorFlashMessage.value = true;
  }
};

const buildFilename = (createdAt: Date) => {
  return `dithercam_${formateDateForFilename(createdAt)}.png`
};

const canShare = () => {
  return !!window.navigator.share
};
</script>

<template>
  <FlashMessage v-if="flashMessage" @timeout="flashMessage = undefined">
    Uploaded
    <Link class="dashed-underline" target="_blank" :href="flashMessage.url">
    {{ flashMessage.filename }}
    </Link>
  </FlashMessage>

  <FlashMessage v-if="printedFlashMessage" @timeout="printedFlashMessage = undefined">
    🖨 Your photo will be printing shortly
  </FlashMessage>

  <menu class="top-nav">
    <li>Dithercam</li>
    <li v-if="isLoggedIn">
      <Link href="/account" class="dashed-underline">account</Link>
    </li>
    <li v-else>
      <Link href="/login" class="dashed-underline">log in</Link>
    </li>
  </menu>

  <ClientOnly>
    <DitherCamera @saveImage="saveImage" :cameraRollReady="cameraRollReady" />
    <CameraRoll @selectImage="selectImage" ref="cameraRoll" @ready="cameraRollReady = true" />
  </ClientOnly>

  <Dialog v-if="selectedImage" class="dialog" @close="selectedImage = undefined" ref="dialog">
    <div class="header">
      <button class="danger" @click="deleteImage">delete</button>
      <label>upscale<input v-model="upscale" type="checkbox" /></label>
      <button class="close" @click="selectedImage = undefined">close</button>
    </div>
    <img :src="selectedImage.blobURL" />
    <menu>
      <button @click="downloadImage">download</button>
      <button v-if="canShare()" @click="shareImage">share</button>
      <button v-if="printerSlug" @click="printImage">print</button>
    </menu>
    <ImageEdit v-if="galleries.length" :blob="selectedImage.blob" :gallerySlug="galleries[0].slug"
      @done="onImageUpload" />

    <FlashMessage v-if="printErrorFlashMessage" @timeout="printErrorFlashMessage = undefined">
      Printing is currently unavailable. Try again soon!
    </FlashMessage>
  </Dialog>
</template>

<style scoped>
.camera-roll:deep(img),
.dialog img {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
  pointer-events: none;
}

.dialog {
  width: 100%;
  max-width: 450px;
}

.dialog .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

menu {
  display: flex;
  padding: 0;
  justify-content: space-evenly;
}

menu.top-nav {
  display: flex;
  margin: 0;
  margin-bottom: 1em;
  padding: 0;
  margin-bottom: 1em;
  justify-content: space-between;
  align-items: center;
}

menu.top-nav li {
  list-style: none;
}
</style>
