<script setup lang="ts">
import { computed } from 'vue'
import { Head, Link } from '@inertiajs/vue3'
import GalleryLink from "~/components/GalleryLink.vue"

const props = defineProps<{
  code: string,
  joinable: { slug: string, type: string, url: string },
  acceptInviteUrl: string,
}>();

const isGallery = computed(() => {
  return props?.joinable?.type == 'Gallery'
})

const isPrinter = computed(() => {
  return props?.joinable?.type == 'Printer'
})
</script>

<template>

  <Head :title="`${joinable.type} Invite`"></Head>
  <p>
    <template v-if="isGallery">You've been invited to join a Gallery called
      <GalleryLink class="dashed-underline" :slug="joinable.slug" />.
    </template>
    <template v-if="isPrinter">
      You've been invited to be a manager for a Ditherprinter called
      <Link class="dashed-underline" :href="joinable.url">{{ joinable.slug }}</Link>.
    </template>
    Click below to make it official!
  </p>

  <Link :href="acceptInviteUrl" method="post" as="button" type="button">Join {{ joinable.slug }}</Link>
</template>
