<script setup lang="ts">
import { ref } from "vue"
import { Link } from '@inertiajs/vue3'
import pluralize from "~/utils/pluralize"
import FlashMessage from "~/components/FlashMessage.vue"

const props = defineProps<{
  invites: { unclaimed: [], claimed: [] },
}>();

const flashMessage = ref();

const copyToClipboard = async (url: string) => {
  navigator.clipboard.writeText(url).then(() => {
    flashMessage.value = "Invite link copied to clipboard";
  }).catch(() => {
    prompt("Copy this URL and send it to someone to invite them", url);
  });
};
</script>

<template>
  <h2 class="heading">Bestow the gift of Dithercam upon unsuspecting loved ones!</h2>
  <p>This is the part of the webpage where you can generate single-use invite
    links, which grant the bearer exclusive access to the Dithercam platform.</p>
  <template v-if="invites.unclaimed.length">
    <p>You've got {{ invites.unclaimed.length }} {{ pluralize('invite', invites.unclaimed.length) }}:</p>
    <ul>
      <li v-for="{ code, url } in invites.unclaimed" :key="code" class="invite unclaimed">
        <a :href="url" @click.prevent="copyToClipboard(url)" class="dashed-underline">
          {{ code }}
        </a>
      </li>
    </ul>
  </template>

  <Link class="create-invite" href="/invites" method="post" as="button" type="button" :preserveScroll="true">Create
  Invite
  </Link>
  <template v-if="invites.claimed.length">
    <p>These codes have already been used and can't be used again, but you can look at them if it makes you nostalgic:
    </p>
    <ul>
      <li v-for="{ code } in invites.claimed" :key="code" class="invite claimed">
        {{ code }}
      </li>
    </ul>
  </template>

  <FlashMessage v-if="flashMessage" @timeout="flashMessage = undefined">
    {{ flashMessage }}
  </FlashMessage>
</template>

<style scoped>
.heading {
  font-size: 1.20em;
}

.invite {
  margin-bottom: 1em;
  list-style-position: inside;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 0.25em;
}

.claimed {
  opacity: 0.5;
  color: var(--grey);
  text-decoration: line-through;
}

.create-invite {
  display: block;
  margin: auto;
}
</style>