
export default (dateString: string) => {
  const date = new Date(dateString);

  const formatPartObjects = new Intl.DateTimeFormat("en-US",
    { month: 'long', day: 'numeric', year: 'numeric', hour: "numeric", minute: "numeric" }
  ).formatToParts(date);

  const parts: Map<Intl.DateTimeFormatPartTypes, string> = new Map();
  formatPartObjects.forEach(({ type, value }) => {
    parts.set(type, value);
  });

  const month = parts.get("month");
  const day = parts.get("day");
  const ord = formatOrdinal(Number.parseInt(day as string));
  const year = parts.get("year");

  const hour = parts.get("hour");
  const minute = parts.get("minute");
  const amPm = parts.get("dayPeriod")?.toLowerCase();

  return `${month} ${day}${ord}, ${year} @ ${hour}:${minute} ${amPm}`;
}

const formatOrdinal = (num: number) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
  const pluralRules = new Intl.PluralRules(
    "en-US",
    { type: "ordinal" }
  );

  const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);

  const rule = pluralRules.select(num);
  return suffixes.get(rule);
};
