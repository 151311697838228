<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'

const props = defineProps<{
  inviteCode?: string
}>();

const form = useForm({
  email: null,
  invite_code: props.inviteCode,
})

const disabled = form.processing
const submitText = props.inviteCode ? "Register" : "Login"
</script>

<template>
  <form class="form" @submit.prevent="form.post('/users/sign_in')">
    <input class="text-input" name="email" type="email" v-model="form.email" :required="true" :autofocus="true"
      placeholder="user@example.com" :disabled="disabled" />
    <button class="submit-button" type="submit" :disabled="disabled">{{ submitText }}</button>
  </form>
</template>

<style scoped>
.form {
  display: flex;
}

.form input {
  flex-grow: 1;
}

.form .submit-button {
  padding: 0.75em;
  font-size: inherit;
}
</style>
