<script setup lang="ts">
import { computed } from 'vue'
import { Head, Link } from '@inertiajs/vue3'
import GalleryLink from "~/components/GalleryLink.vue"
import LoginForm from "~/components/LoginForm.vue"

const props = defineProps<{
  code: string,
  joinable?: { slug: string, type: string, url: string },
}>();

const isGallery = computed(() => {
  return props?.joinable?.type == 'Gallery'
})

const isPrinter = computed(() => {
  return props?.joinable?.type == 'Printer'
})
</script>

<template>

  <Head title="Join the pixel party"></Head>
  <p>Input your email below to join all sorts of cool people on the ditheriest website around!</p>
  <p v-if="joinable">
    <template v-if="isGallery">
      Furthermore, you will gain access to this person's Gallery called
      <GalleryLink class="dashed-underline" :slug="joinable.slug" />.
    </template>
    <template v-if="isPrinter">
      Furthermore, you will be added as a manager for a Ditherprinter called
      <Link class="dashed-underline" :href="joinable.url">{{ joinable.slug }}</Link>.
    </template>
  </p>
  <LoginForm :inviteCode="code" />
</template>
