<script setup lang="ts">
import { ref, reactive, computed } from "vue"
import betterFetch from "~/utils/betterFetch";
import slugify from "~/utils/slugify";
import isValidSlug from "~/utils/isValidSlug";
import FlexibleTextArea from "~/components/FlexibleTextArea.vue";

const props = defineProps<{
  blob?: Blob,
  id?: string,
  gallerySlug: string,
  caption?: string,
  altText?: string,
  slug?: string,
}>();

const emit = defineEmits<{
  (e: 'update:caption', value?: string): void
  (e: 'update:altText', value?: string): void
  (e: 'update:slug', value: string): void
  (e: 'changeSaveDisabled', value: boolean): void
  (e: 'done', permalink?: string, filename?: string): void
}>();

const id = ref(props.id);
const editFields = reactive({
  caption: props.caption, altText: props.altText, slug: props.slug,
});

const onSlugInput = (event: Event) => {
  const input = event.currentTarget as HTMLInputElement;
  editFields.slug = slugify(input.value);
};

const onCancel = () => {
  emit("done");
};

const onSave = async () => {
  const url = `/galleries/${props.gallerySlug}/images/${id.value || ""}`;

  const response = await betterFetch(url, id.value ? "put" : "post", {
    data: props.blob || undefined,
    alt_text: editFields.altText || undefined,
    caption: editFields.caption || undefined,
    slug: editFields.slug || undefined,
  });

  if (response) {
    const { permalink, slug } = response;
    emit("update:caption", editFields.caption);
    emit("update:altText", editFields.altText);
    editFields.slug && emit("update:slug", editFields.slug);
    emit("done", permalink, `${slug}.bmp`);
  } else {
    alert("That didn't work. Maybe try again?");
  }
};
defineExpose({ save: onSave });

const slugError = computed(() => {
  if (!id.value && !editFields.slug) {
    return;
  }

  if (!isValidSlug(editFields.slug || "")) {
    return "⚠️ Filename can only contain letters, numbers, and underscores and can't be longer than 50 characters";
  }
});

const disabled = computed(() => {
  const value = !!slugError.value;
  emit("changeSaveDisabled", value);
  return value;
});
</script>

<template>
  <label>caption
    <FlexibleTextArea class="text-area" v-model="(editFields.caption as string)"
      placeholder="Why not write some witty, wry, or whimsical words which would work well with this?" />
  </label>
  <label>alt text
    <FlexibleTextArea class="text-area" v-model="(editFields.altText)"
      placeholder="Describe the picture for those that can't see it" />
  </label>

  <label>
    filename
    <div class="slug-wrapper text-input">
      <div class="input-wrapper">
        <input :value="editFields.slug" @input="onSlugInput" placeholder="filename" required maxlength="50" />
      </div>
    </div>
    <div v-if="slugError" class="slug-error">{{ slugError }}</div>
  </label>

  <menu>
    <li>
      <button @click="onCancel">cancel</button>
    </li>
    <li>
      <button :disabled="disabled" @click="onSave">{{ id ? "save" : "upload" }}</button>
    </li>
  </menu>
</template>

<style scoped>
.text-area {
  margin-bottom: 1em;
}

.slug-wrapper {
  padding: 0;
}

.slug-wrapper .input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slug-wrapper .input-wrapper:after {
  content: ".bmp";
  padding-right: 1em;
}

.slug-wrapper input {
  color: inherit;
  font-size: inherit;
  padding: 1em;
  border: none;
  background: none;
  width: 100%;
}

.slug-error {
  margin: 1em 0;
  color: var(--red);
}

menu {
  display: flex;
  margin: 0;
  margin-top: 1em;
  padding: 0;
  margin-bottom: 1em;
  justify-content: space-between;
  align-items: center;
}

menu li {
  list-style: none;
}
</style>
