
export default (date: Date) => {
  const formatPartObjects = new Intl.DateTimeFormat("en-US",
    { month: '2-digit', day: '2-digit', year: 'numeric', hour: "2-digit", minute: "2-digit", second: "numeric" }
  ).formatToParts(date);

  const parts: Map<Intl.DateTimeFormatPartTypes, string> = new Map();
  formatPartObjects.forEach(({ type, value }) => {
    parts.set(type, value);
  });

  const year = parts.get("year");
  const month = parts.get("month");
  const day = parts.get("day");

  const hour = parts.get("hour");
  const minute = parts.get("minute");
  const seconds = parts.get("second");
  const amPm = parts.get("dayPeriod")?.toLowerCase();

  return `${year}-${month}-${day}_${hour}.${minute}.${seconds}.${amPm}`;
}
