<script setup lang="ts">
import { ref, computed, onMounted } from "vue"

export interface Props {
  persistent: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  persistent: false,
})

const emit = defineEmits<{
  (e: "timeout"): void
}>();

const visible = ref(true);
const displayDuration = 2000;
const transitionDuration = ref(500);
const transitionDurationWithUnit = computed(() => {
  return `${transitionDuration.value}ms`;
});

onMounted(() => {
  window.setTimeout(dismiss, displayDuration);
});

const dismiss = () => {
  if (props.persistent) {
    return;
  }

  visible.value = false;
  window.setTimeout(() => {
    emit("timeout");
  }, transitionDuration.value);
};

</script>

<template>
  <div :class="['wrapper', { visible, persistent }]" @click="dismiss">
    <div class="inner">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  position: fixed;
  z-index: 10;
  top: 1em;
  display: flex;
  justify-content: center;
  left: 0;
  margin-bottom: 1em;
  width: 100%;
  cursor: default;
  pointer-events: none;
  opacity: 0;
  transition: opacity v-bind(transitionDurationWithUnit);
}

.wrapper.persistent {
  position: initial;
}

.inner {
  background: #000;
  padding: 1em;
  border: 1px solid var(--lightest-green);
  pointer-events: all;
}

.visible {
  opacity: 1;
}
</style>
