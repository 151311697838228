export default async (
  url: string,
  method: string,
  params?: { [key: string]: string | Blob | undefined },
) => {
  const formData = new FormData()

  for (const property in params) {
    if (params[property] !== undefined) {
      formData.append(property, params[property] as string | Blob);
    }
  }

  const crfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
  formData.append('authenticity_token', crfToken || '');

  return (await fetch(url, { method, body: formData })).json()
};
